<template>
  <BaseModal
    :id="id"
    :title="title"
    :close-direction="closeDirection"
    :show="show"
    :width="width"
    :height="height"
    :cancel-button-text="cancelButtonText"
    :confirm-button-text="confirmButtonText"
    :show-footer-actions="false"
    @close-modal="closeModal"
  >
    <template v-slot:content>
      <v-stepper v-model="currentStep" color="transparent">
        <v-stepper-header>
          <div v-for="(item, index) in steps" :key="index">
            <v-stepper-step
              :complete="currentStep > item"
              :step="item"
              class="no-padding"
            >
              {{ stepTitles[index] }}
            </v-stepper-step>
          </div>
        </v-stepper-header>

        <v-divider />
      </v-stepper>

      <span v-for="(item, index) in steps" :key="index">
        <slot v-if="currentStep === item" :name="`step-${item}`" />
      </span>
      <v-divider class="my-5" />
      <div class="d-flex align-end justify-end pt-4 pb-4">
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2"
          @click="changeStep('cancel-previous')"
        >
          {{ currentCancelButtonText }}
        </botao-padrao>
        <botao-padrao
          color="primary"
          :disabled="!nextConfirmEnabled"
          @click="changeStep('confirm-next')"
        >
          {{ currentConfirmButtonText }}
        </botao-padrao>
      </div>
    </template>
  </BaseModal>
</template>

<script>
const validators = {
  show: [true, false],
  position: ['center', 'left', 'right'],
  closeDirection: ['left', 'right'],
  buttonsDirection: ['center', 'left', 'right'],
  nextConfirmEnabled: [true, false],
};
const defaultValues = {
  title: '',
  width: 600,
  height: 1000,
  position: 'center',
  show: false,
  closeDirection: 'right',
  cancelButtonText: 'cancel',
  previousButtonText: 'previous',
  confirmButtonText: 'confirm',
  nextButtonText: 'next',
  buttonsDirection: 'right',
  currentStep: 1,
  steps: 0,
  stepTitles: [],
  nextConfirmEnabled: false,
};

export default {
  name: 'BaseStepperModal',
  components: {
    BaseModal: () => import('@components/fragments/BaseModal'),
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: defaultValues.show,
      validator: (value) => {
        return validators.show.includes(value);
      },
    },
    position: {
      type: String,
      default: defaultValues.position,
      validator: (value) => {
        return validators.position.includes(value);
      },
    },
    title: {
      type: String,
      default: defaultValues.title,
    },
    width: {
      type: Number,
      default: defaultValues.width,
    },
    height: {
      type: Number,
      default: defaultValues.height,
    },
    closeDirection: {
      type: String,
      default: defaultValues.closeDirection,
      validator: (value) => {
        return validators.closeDirection.includes(value);
      },
    },
    cancelButtonText: {
      type: String,
      default: defaultValues.cancelButtonText,
    },
    previousButtonText: {
      type: String,
      default: defaultValues.previousButtonText,
    },
    confirmButtonText: {
      type: String,
      default: defaultValues.confirmButtonText,
    },
    nextButtonText: {
      type: String,
      default: defaultValues.nextButtonText,
    },
    buttonsDirection: {
      type: String,
      default: defaultValues.buttonsDirection,
      validator: (value) => {
        return validators.buttonsDirection.includes(value);
      },
    },
    currentStep: {
      type: Number,
      default: defaultValues.currentStep,
      validator: (value) => {
        return value >= 1;
      },
    },
    steps: {
      type: Number,
      default: defaultValues.steps,
    },
    stepTitles: {
      type: Array,
      default: () => {
        return defaultValues.stepTitles;
      },
      validator: (value) => {
        return value.length;
      },
    },
    nextConfirmEnabled: {
      type: Boolean,
      default: defaultValues.nextConfirmEnabled,
      validator: (value) => {
        return validators.nextConfirmEnabled.includes(value);
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    buttonDirection() {
      const directionClass =
        this.buttonsDirection &&
        validators.buttonsDirection.includes(this.buttonsDirection)
          ? this.buttonsDirection
          : defaultValues.buttonsDirection;

      return {
        textAlign: directionClass,
      };
    },
    currentCancelButtonText() {
      if (this.currentStep === 1) return this.cancelButtonText;

      return this.previousButtonText;
    },
    currentConfirmButtonText() {
      if (this.currentStep === this.steps) return this.confirmButtonText;
      return this.nextButtonText;
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    changeStep(type) {
      if (type === 'cancel-previous') {
        if (this.currentStep === 1) {
          this.closeModal();
        } else {
          const newStep = this.currentStep - 1;

          this.$emit('change-step', newStep);
        }
      } else {
        if (this.currentStep === this.steps) {
          this.$emit('last-step', this.steps);
        } else {
          const newStep = this.currentStep + 1;

          this.$emit('change-step', newStep);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-stepper::v-deep {
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  height: 60px !important;
  margin-top: -8px !important;

  .v-stepper__header {
    position: relative;
    z-index: 2;

    div {
      background: #ffffff;
    }
  }

  .v-divider {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    right: 0;
  }
}

:deep(.v-stepper) {
}
</style>
